iframe {
  display: none;
}

@font-face {
  font-family: 'saf';
  font-style: normal;
  font-weight: normal;
  src: url('saf.otf');
}

.saf {
  font-family: 'saf'
}

@font-face {
  font-style: normal;
  font-weight: normal;
  font-family: 'harmonyOSSansBold';
  src: url('harmonyOSSansBold.ttf');
}

.harmonyOSSansBold {
  font-family: 'harmonyOSSansBold'
}

* {
  margin: 0;
  padding: 0;
  @extend .harmonyOSSansBold
}


body {
  @extend .margin0Auto;
}

img {
  object-fit: cover;
}

$fontSize: .3rem;
$fontSizePhone: 1.2rem;
$smallMultiple: .8;
//关联main.js的fontSize
$fullWidthSize: 30rem;
$pagePaddingSizeUnit: .25rem;
$paddingSize: $fontSize/1.5;
$paddingSizePhone: $fontSizePhone/1.5;
$paddingSizeX2: $paddingSize*2;
$smallFontSize: $fontSize*$smallMultiple;
$smallFontSizePhone: $fontSizePhone*$smallMultiple;
$insetBottom: env(safe-area-inset-bottom);
$grayLineSize: $fontSize/2;
$smallGrayLineSize: $fontSize/7.5;
$grayLineSizePhone: $fontSizePhone/2;
$smallGrayLineSizePhone: $fontSizePhone/10;
$lightGray: #979797;
$powerColor: #FF4F00;
$power360Color: yellow;
$power360TopColor: #202640;
$domainNameColor0: #445cf1;
$domainNameColor1: $power360Color;
$domainNameColor2: #ff8625;
$pageTitleHeight: $fontSize*2;
$marginTopPageTitle: $pageTitleHeight + $paddingSizeX2;
$blue: #3092FC;
$green: #499318;
$red: #FF5A40;
$handleContainerHeight: $fontSize*5;
$miningMachineryDetailDeepColor: black;
$activeColor: #BAFF00;
//@media screen and (min-width: 1024px) {
body {
  min-width: 100%;
}

div, span, pre, input, textarea {
  font-size: $fontSize;
}

.sm-pop-inner {
  position: relative;
  padding-bottom: $fontSize*2;
}

$ncContainerHeight: 48px;
$btnHeight: $ncContainerHeight*.955;
.borderRadius {
  overflow: hidden;
  border-radius: $fontSize/1.5;
}

.ncContainerHeight {
  height: $ncContainerHeight !important;
}

.btnClass {
  width: $btnHeight !important;
  height: $btnHeight !important;
  @extend .flexCenter, .borderRadius;
}

.nc-container {
  @extend .flexCenter, .borderRadius;

  & > div {
    width: 100% !important;
  }

  .nc_wrapper {
    width: 100% !important;
  }

  .nc_scale {
    @extend .ncContainerHeight;

    .btn_slide {
      @extend .btnClass;
      background: $power360Color !important;
    }

    .btn_ok {
      @extend .btnClass;
      background: black !important;
      color: $power360Color !important;
    }

    & .scale_text2 {
      color: transparent !important;
    }

    .nc-align-center.scale_text2 {
      z-index: 3;
      @extend .flexCenter;
      text-indent: 0 !important;
    }

    .nc_ok, .nc_bg {
      z-index: 2;
      @extend .ncContainerHeight;
      background: $power360Color !important;
    }

    .scale_text {
      @extend .flexCenter, .ncContainerHeight;
    }
  }
}

//}

//@media screen and (max-width: 1024px) {
//  body {
//    min-width: 100%;
//  }
//
//  div, span, pre, input, textarea {
//    font-size: $fontSizePhone;
//  }
//  .sm-pop-inner {
//    position: relative;
//    padding-bottom: $fontSizePhone;
//  }
//  $ncContainerHeight: 4rem;
//  $btnHeight: $ncContainerHeight*.97;
//  .ncContainerHeightPhone {
//    height: $ncContainerHeight !important;
//  }
//
//  .btnClassPhone {
//    width: $btnHeight !important;
//    height: $btnHeight !important;
//    @extend .flexCenter;
//    overflow: hidden;
//    border-radius: $fontSizePhone/1.5;
//  }
//  .nc-container {
//    @extend .flexCenter;
//    overflow: hidden;
//    width: 100% !important;
//    border-radius: $fontSizePhone/1.5;
//
//    & > div {
//      width: 100% !important;
//    }
//
//    .nc_wrapper {
//      width: 100% !important;
//      height: $ncContainerHeight !important;
//    }
//
//    .nc_scale {
//      @extend .ncContainerHeightPhone;
//
//      .btn_slide {
//        @extend .btnClassPhone;
//        background: $power360Color !important;
//      }
//
//      .btn_ok {
//        @extend .btnClassPhone;
//        background: black !important;
//        color: $power360Color !important;
//      }
//
//      & .scale_text2 {
//        color: transparent !important;
//      }
//
//      .nc-align-center.scale_text2 {
//        z-index: 3;
//        @extend .flexCenter;
//        text-indent: 0 !important;
//      }
//
//      .nc_ok, .nc_bg {
//        z-index: 2;
//        @extend .ncContainerHeightPhone;
//        background: $power360Color !important;
//      }
//
//      .scale_text {
//        @extend .flexCenter, .ncContainerHeightPhone;
//      }
//    }
//  }
//  .el-pagination {
//    background: #F4F5F7;
//    justify-content: center !important;
//  }
//  .el-pagination.is-background {
//    & .btn-next, .btn-prev, .el-pager li {
//      width: 2.6rem !important;
//      height: 2.6rem !important;
//      min-width: 0 !important;
//      margin: .1rem !important;
//      line-height: 2.5rem;
//      font-size: $smallFontSizePhone !important;
//    }
//  }
//}

.width100 {
  width: 100%;
}

.height100 {
  height: 100%;
}

.paddingBig {
  padding: $fontSize;
}

.paddingBigger {
  padding: $fontSize*1.6;
}

.padding {
  padding: $paddingSize;
}

.paddingSmall {
  padding: $paddingSize/4 $paddingSize/2;
}

.paddingTop {
  padding-top: $paddingSize;
}

.paddingTopBig {
  padding-top: $fontSize;
}

.paddingLeft {
  padding-left: $paddingSize;
}

.paddingRight {
  padding-right: $paddingSize;
}

.paddingRightBig {
  padding-right: $fontSize;
}

.paddingLeftBig {
  padding-left: $fontSize;
}

.paddingBottom {
  padding-bottom: $paddingSize;
}

.paddingBottomBig {
  padding-bottom: $fontSize;
}

.paddingTopBigger {
  padding-top: $fontSize*2;
}

.paddingBottomBigger {
  padding-bottom: $fontSize*2;
}

.paddingTopBottom {
  padding: $paddingSize 0;
}

.paddingTopBottomBig {
  padding: $fontSize 0;
}

.paddingLeftRight {
  padding: 0 $paddingSize;
}

.paddingTopLeftRight {
  padding: $paddingSize $paddingSize 0 $paddingSize;
}

.paddingTopLeftRightBig {
  padding: $fontSize $fontSize 0 $fontSize;
}

.paddingBottomLeftRight {
  padding: 0 $paddingSize $paddingSize $paddingSize;
}

.paddingLeftRightBig {
  padding-left: $fontSize;
  padding-right: $fontSize;
}

.paddingLeftRightBigger {
  padding-left: $fontSize*1.6;
  padding-right: $fontSize*1.6;
}

.paddingBottomLeftRightBig {
  padding: 0 $fontSize $fontSize $fontSize;
}

.colorWhite {
  color: white;
}

.colorBlack {
  color: black;
}

.colorGray {
  color: gray;
}

.bigFontSize {
  font-size: $fontSize/$smallMultiple;
}

.biggerFontSize {
  font-size: $fontSize*2;
}

.fontSize {
  font-size: $fontSize;
}

.smallFontSize {
  font-size: $smallFontSize;
}

.smallerFontSize {
  font-size: $fontSize*$smallMultiple;
}

.metapsisBigFontSize {
  font-size: $fontSize*3;
}

.metapsisBiggerFontSize {
  font-size: $fontSize*4;
}

.textAlignCenter {
  text-align: center;
}

.textAlignRight {
  text-align: right;
}

.textAlignLeft {
  text-align: left;
}

.flex {
  display: flex;
}

.flexColumnCenter {
  @extend .flexColumn, .flexCenter;
}

.flexColumn {
  @extend .flex;
  flex-direction: column;
}

.flexCenter {
  @extend .justifyContentCenter, .flex, .alignItemsCenter;
}

.flexGrow1 {
  width: 0;
  flex-grow: 1;
}

.flexGrow2 {
  width: 0;
  flex-grow: 2;
}

.flexGrow1Column {
  height: 0;
  flex-grow: 1;
}

.flexShrink0 {
  flex-shrink: 0;
}

.flexWrap {
  flex-wrap: wrap
}

.textOverflowEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin square($size:0) {
  width: $size;
  height: $size;
}

@mixin circle($size:0) {
  @include square($size);
  border-radius: 50%;
}

.margin {
  margin: $paddingSize;
}

.marginTop {
  margin-top: $paddingSize;
}

.marginTopBig {
  margin-top: $fontSize;
}

.marginBottom {
  margin-bottom: $paddingSize;
}

.marginTop0 {
  margin-top: 0
}

.marginLeft {
  margin-left: $paddingSize;
}

.marginRight {
  margin-right: $paddingSize;
}

.marginLeftBig {
  margin-left: $fontSize;
}

.marginLeftRight {
  margin: 0 $paddingSize;
}

.margin0Auto {
  margin: 0 auto
}

.paddingTopPageTitle {
  padding-top: $marginTopPageTitle;
}

.paddingInsetBottom {
  padding-bottom: $insetBottom;
}

.marginInsetBottom {
  margin-bottom: $insetBottom;
}

.childrenSpacingTop {
  & > *:first-child {
    margin-top: 0;
  }

  & > * {
    @extend .marginTop;
  }
}

.childrenSpacingTopBig {
  & > *:first-child {
    margin-top: 0;
  }

  & > * {
    @extend .marginTopBig;
  }
}

.childrenSpacingLeft {
  & > *:first-child {
    margin-left: 0;
  }

  & > * {
    @extend .marginLeft;
  }
}

.childrenSpacingLeftSmall {
  & > *:first-child {
    margin-left: 0;
  }

  & > * {
    margin-left: $paddingSize/2;
  }
}

.childrenSpacingLeftBig {
  & > *:first-child {
    margin-left: 0;
  }

  & > * {
    @extend .marginLeftBig;
  }
}

.childrenSpacingRightBig {
  & > * {
    margin-right: $fontSize;
  }
}

.childrenSpacingLeftBigger {
  & > *:first-child {
    margin-left: 0;
  }

  & > * {
    margin-left: $fontSize*2;
  }
}

.childrenSpacingTopBigger {
  & > *:first-child {
    margin-top: 0;
  }

  & > * {
    margin-top: $fontSize*2;
  }
}

.childrenTextJustify {
  & > :first-child {
    @extend .textAlignLeft;
    justify-content: flex-start;
  }

  & > * {
    @extend .justifyContentCenter, .textAlignCenter,;
  }

  & > :last-child {
    @extend .textAlignRight;
    justify-content: flex-end;
  }
}

.childrenFlexGrow1 {
  & > * {
    @extend .flexGrow1;
  }
}

.childrenTextOverflowEllipsis {
  & > * {
    @extend .textOverflowEllipsis;
  }
}

.backgroundTransparent {
  background: transparent !important;
}

.backgroundWhite {
  background: white;
}

.backgroundBlack {
  background: black;
}

.backgroundGrey {
  background: #F0F2F5;
}

.backgroundRed {
  background: red;
}

.backgroundGreen {
  background: green;
}

$borderRadiusSize: $paddingSize;
.borderRadius {
  overflow: hidden;
  border-radius: $borderRadiusSize;
}

.borderRadius999 {
  overflow: hidden;
  border-radius: 999px !important;
}

.objectFitContain {
  object-fit: contain;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentSpaceAround {
  justify-content: space-around
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.justifyContentFlexEnd {
  justify-content: flex-end
}

.alignItemsCenter {
  align-items: center;
}

.alignItemsFlexEnd {
  align-items: flex-end
}

@mixin borderTopGrayLine($size:$grayLineSize) {
  border-top: solid $size $lightGray;
}

.borderGrayLine {
  border: solid $grayLineSize $lightGray;
}

.borderSmallGrayLine {
  border: solid 1px $lightGray;
}

.borderSmallBlackLine {
  border: solid 1px black;
}

.borderTopGrayLine {
  @include borderTopGrayLine();
}

.borderTopSmallGrayLine {
  @include borderTopGrayLine($smallGrayLineSize);
}

.borderBottomGrayLine {
  border-bottom: solid $grayLineSize $lightGray;
}

.borderBottomSmallGrayLine {
  border-bottom: solid $smallGrayLineSize $lightGray;
}

.borderLeftSmallGrayLine {
  border-left: solid 1px $lightGray;
}

.borderRightSmallGrayLine {
  border-right: solid 1px $lightGray;
}

.domainNameColor0 {
  color: $powerColor;
}

.domainNameColor1 {
  color: $powerColor;
}

.domainNameColor2 {
  color: $powerColor;
}


.domainNameBackGroundColor0 {
  background: $domainNameColor0;
}

.domainNameBackGroundColor1 {
  background: $domainNameColor1;
}

.domainNameBackGroundColor2 {
  background: $domainNameColor2;
}

.domainNameBorder0 {
  border: solid $smallGrayLineSize $domainNameColor0;
}

.domainNameBorder1 {
  border: solid $smallGrayLineSize $domainNameColor1;
}

.domainNameBorder2 {
  border: solid $smallGrayLineSize $domainNameColor2;
}

.domainNameBorderColor0 {
  border-color: $domainNameColor0;
}

.domainNameBorderColor1 {
  border-color: $domainNameColor1;
}

.domainNameBorderColor2 {
  border-color: $domainNameColor2;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $fontSize/3 $fontSize/3 $fontSize/3 $fontSize/3;
  border-color: $miningMachineryDetailDeepColor transparent transparent transparent;
}

.triangleRight {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $fontSize/3 $fontSize/3 $fontSize/3 $fontSize/3;
  border-color: transparent transparent transparent $miningMachineryDetailDeepColor;
}

.titleTipsSize {
  height: $fontSize;
  width: $fontSize/5;
  @extend .borderRadius999;
}

.titleTips0 {
  @extend .titleTipsSize, .domainNameBackGroundColor0;
}

.titleTips1 {
  @extend .titleTipsSize, .domainNameBackGroundColor1;
}

.titleTips2 {
  @extend .titleTipsSize, .domainNameBackGroundColor2;
}

$navigationHeight: $fontSize*22;
.marginNavigationHeight {
  margin-bottom: $navigationHeight;
}

.fullWidth {
  width: $fullWidthSize;
  @extend .margin0Auto;
}

.smallButtonBorderRadius {
  border-radius: $fontSize/3
}

.boxShadow {
  box-shadow: 0 0 $fontSize lightgray;
}

.backgroundSizeCover {
  background-size: cover !important;
}

.backgroundImageStyle {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.backGroundColorGray {
  background-color: #CCCCCC;
}

.backGroundColorLightGray {
  background-color: $lightGray;
}

.backGroundBlueGray {
  background: #F3F6FE;
}

.backGroundColorLightBlue {
  background: #EBF3FF
}

.preWrap {
  white-space: pre-wrap;
}

pre {
  @extend .preWrap
}

textarea {
  border: none;
  @extend .backgroundTransparent
}

.fixedBottomContainer {
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  @extend .fullWidth, .paddingInsetBottom;
}

input {
  background: none;
  outline: none;
  border: none;
}

.overflowScroll {
  overflow: scroll
}

.overflowXScroll {
  overflow-x: scroll
}

.overflowYScroll {
  overflow-y: scroll
}

.overflowVisible {
  overflow: visible
}

.overflowHidden {
  overflow: hidden;
}

.boxSizingBorderBox {
  box-sizing: border-box
}

.grayPageContainer {
  top: $marginTopPageTitle;
  @extend .boxSizingBorderBox, .overflowScroll, .fixedBottomContainer, .backGroundColorLightGray;

  & > * {
    @extend .backgroundWhite;
  }
}

.colorBlue {
  color: $blue;
}

.backgroundBlue {
  background: $blue;
}

.textJustifyContainerNoFlexGrow1 {
  @extend .flexCenter, .justifyContentSpaceBetween, .childrenTextJustify, .childrenSpacingLeft;
}

.textJustifyContainer {
  @extend .textJustifyContainerNoFlexGrow1, .childrenFlexGrow1;
}

.squareFontSize {
  @include square($fontSize)
}

.backgroundPurpleGradient {
  background: linear-gradient(to right, #4A5891, #374167);
}

.backgroundBlueGradient {
  background: linear-gradient(to right, #2584FF, #5658F1)
}

.colorLightWhite {
  color: rgba(255, 255, 255, .6);
}

.colorGreen {
  color: $green;
}

.colorRed {
  color: $red;
}

.backgroundGreen {
  background: $green;
}

.mint-indicator-wrapper {
  z-index: 9999 !important;
}

.mint-indicator-mask {
  z-index: 9999 !important;
}

.mint-toast {
  z-index: 9999 !important;
}

.uBitHandleButton {
  @extend .flexCenter, .borderRadius, .paddingBig, .colorWhite;
}

.certificationLabelValue {
  @extend .childrenSpacingTopBig, .paddingBottomBig, .borderBottomSmallGrayLine;
}

.wordBreakBreakAll {
  word-break: break-all
}

.littleDataContainer {
  padding: $paddingSize/5 $paddingSize/2;
  @extend .colorWhite, .smallFontSize, .smallButtonBorderRadius;
}


.borderSmallBlackLineDataContainer {
  padding: 3px 6px;
  @extend .borderSmallBlackLine, .smallButtonBorderRadius, .textOverflowEllipsis;
}

.darkLittleDataContainer {
  background: #3A3D47;
  @extend .littleDataContainer;
}

.lineHeight1 {
  line-height: 1
}

.fontWeightBolder {
  font-weight: bolder;
}

.fontWeightLighter {
  font-weight: lighter
}

.positionAbsolute {
  position: absolute
}

.positionRelative {
  position: relative
}

.positionSticky {
  position: sticky;
}

.positionFixed {
  position: fixed;
}

.opacity {
  opacity: .7;
}

.threeGiftsGoldBorder {
  border: solid $smallGrayLineSize #FEF4BA;
}

.threeGiftsRedColor {
  color: #D0340F;
}

$threeGiftsGoldColor: #FFDF44;
.threeGiftsGoldColor {
  color: $threeGiftsGoldColor;
}

.goldBackgroundLinearGradient {
  background: linear-gradient(180deg, #F0FF99 0%, $threeGiftsGoldColor 100%);
}

.backgroundClipText {
  @extend .fontWeightBolder;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}


.threeGiftsBigGoldText {
  @extend .biggerFontSize, .goldBackgroundLinearGradient, .backgroundClipText;
}

.threeGiftsBigGoldTextItalic {
  font-style: italic;
  @extend .threeGiftsBigGoldText;
}

.threeGiftsLightGoldColor {
  color: #F2E8B9;
}

.threeGiftsButton {
  @extend .borderRadius, .textAlignCenter, .padding,
  .goldBackgroundLinearGradient, .threeGiftsRedColor, .bigFontSize, .fontWeightBolder;
}

.zIndex999 {
  z-index: 999
}

.threeGiftsPre {
  line-height: 2;
  @extend .threeGiftsLightGoldColor, .textAlignLeft;
}

.couponCollectionBackground {
  background: #5E3818;
}

.borderBigButton {
  @extend .padding, .textAlignCenter, .borderRadius;
}

.el-slider__runway {
  margin-bottom: 0 !important;
}

.el-slider__button-wrapper {
  z-index: 0 !important;
}

.fontSizeFontWeightBolder {
  @extend .fontSize, .fontWeightBolder;
}

.textDecorationLineThrough {
  text-decoration: line-through
}

.bgImage {
  height: $fontSize*3;
  @extend .width100, .backgroundImageStyle;
}

.displayBlock {
  display: block
}

.power360Color {
  color: $power360Color;
}

.power360BackgroundColor {
  background: $power360Color;
}

.power360TopBackgroundColor {
  background: $power360TopColor;
}

.power360LinearGradient {
  background: linear-gradient(90deg, #F2F8FF 0%, #DDEEFF 100%);
}

.smallButtonPadding {
  padding: $paddingSize/2.5 $paddingSize;
}

.zIndex1 {
  z-index: 1;
}

.zIndex-1 {
  z-index: -1;
}

.unreadPoint {
  background: red;
  @include circle($fontSize);
}

.power360Border {
  border: solid $smallGrayLineSize $power360Color;
}

.buttonHoverBlack:hover {
  color: white !important;
  background: black !important;
}

.power360BigButton {
  overflow: hidden;
  @extend .cursorPointer, .floatingUpProjection, .borderRadius, .paddingTopBottomBig, .textAlignCenter;
}

.power360Button {
  @extend .power360BigButton, .backgroundActiveColor;
}

.pleaseLoginRegister1Container {
  height: $fullWidthSize;
}

.cycleTag {
  border-bottom-right-radius: $paddingSize;
  @extend .positionAbsolute, .paddingSmall, .smallerFontSize, .colorWhite
}

.powerColor {
  color: $powerColor;
}

.powerBackgroundColor {
  background: $powerColor;
}

.el-slider__runway {
  .el-slider__bar {
    background: $power360Color;
  }
}

.el-switch__core:after {
  background: black !important;
}

.miningMachineryDetailBackgroundDeepColor {
  background: $miningMachineryDetailDeepColor;
}

.filterGrayscale {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}


.nc-container {
  @extend .flexCenter;

  .nc_scale {
    @extend .borderRadius;

    .btn_slide {
      color: white !important;
      background: black !important;
    }

    .btn_ok {
      background: black !important;
      color: $power360Color !important;
    }

    & .scale_text2 {
      color: transparent !important;
    }

    .nc-align-center.scale_text2 {
      z-index: 3;
      @extend .flexCenter;
      text-indent: 0 !important;
    }

    .nc_ok, .nc_bg {
      z-index: 2;
      background: $power360Color !important;
    }

    .scale_text {
      @extend .flexCenter;
    }
  }
}

$pagePaddingLeftRightSize: $pagePaddingSizeUnit*10;
$pagePaddingTopBottomSize: $pagePaddingLeftRightSize/4;
.pagePadding {
  padding: $pagePaddingTopBottomSize $pagePaddingLeftRightSize;
}

.pagePaddingLeftRight {
  padding: 0 $pagePaddingLeftRightSize;
}

.pagePaddingTopBottom {
  padding: $pagePaddingTopBottomSize 0;
}

.cursorPointer {
  cursor: pointer
}

.floatingUpTransform:hover {
  transform: translateY(-10px);
}

$floatingUpProjectionSize: $fontSize/2;
.floatingUpProjection:hover {
  box-shadow: $floatingUpProjectionSize/2 $floatingUpProjectionSize $floatingUpProjectionSize 0 rgba(134, 134, 134, 0.1);
}

.textHover {
  color: #8C8C8C;

  &:hover {
    color: black;
  }
}

.textHoverWhite {
  color: #8C8C8C;

  &:hover {
    @extend .colorWhite
  }
}

.el-carousel__indicator button {
  opacity: .2;
}

.el-carousel__button {
  background: black !important;
}

$productItemWidth: $fontSize*18;
.productItemWidth {
  min-width: $productItemWidth;
  width: $productItemWidth;
}

.cloudMiningContent {
  @extend .productItemWidth;
  @include square($productItemWidth);
}

$pagePaddingTopBottomSize: $pagePaddingSizeUnit*5;
$homePaddingLeftRightSize: $pagePaddingLeftRightSize+$pagePaddingTopBottomSize;
.homePaddingTop {
  padding-top: $pagePaddingTopBottomSize;
}

$bannerMintSwipeWidth: $fullWidthSize;
.bannerMintSwipe {
  height: $bannerMintSwipeWidth / 2.8;
  width: $bannerMintSwipeWidth;
}

.el-carousel__container {
  height: 112.3px !important;
}

.homeModularPaddingLeftRight {
  @extend .flexColumnCenter;

  & > * {
    min-width: 1000px;
    max-width: 1000px;
  }
}

.homeModularPadding {
  @extend .homeModularPaddingLeftRight;
  padding: $pagePaddingTopBottomSize 0;
}

.pageFixedIconRight {
  right: $pagePaddingLeftRightSize/2;
}

.pageFixedIconSize {
  @extend .pageFixedIconRight;
  @include square($fontSize*3);
}

.productModulePadding {
  padding: 60px;
}

.iconWidthBig {
  width: $fontSize*1.6;
}

.el-pagination.is-background {
  & .el-pager li:not(.disabled).active {
    background: black !important;
  }

  & .btn-next, .btn-prev {
    @extend .flexCenter;
  }

  & .btn-next, .btn-prev, .el-pager li {
    overflow: hidden;
    border: 1px solid $lightGray;
    border-radius: 5px !important;
    background: white !important;
  }
}


.nationalFlagSize {
  @include circle($fontSize*1.2);
}

.userListRightContainer {
  @extend .backgroundWhite;
  padding: 20px;
}


//////////////////////////////////////////////////////////////////


.paddingBigPhone {
  padding: $fontSizePhone;
}

.paddingBiggerPhone {
  padding: $fontSizePhone*2;
}

.paddingPhone {
  padding: $paddingSizePhone;
}

.paddingSmallPhone {
  padding: $paddingSizePhone/4 $paddingSizePhone/2;
}

.paddingTopPhone {
  padding-top: $paddingSizePhone;
}

.paddingTopBigPhone {
  padding-top: $fontSizePhone;
}

.paddingLeftPhone {
  padding-left: $paddingSizePhone;
}

.paddingRightPhone {
  padding-right: $paddingSizePhone;
}

.paddingLeftBigPhone {
  padding-left: $fontSizePhone;
}

.paddingBottomPhone {
  padding-bottom: $paddingSizePhone;
}

.paddingBottomBigPhone {
  padding-bottom: $fontSizePhone;
}

.paddingTopBiggerPhone {
  padding-top: $fontSizePhone*2;
}

.paddingBottomBiggerPhone {
  padding-bottom: $fontSizePhone*2;
}

.paddingTopBottomPhone {
  padding: $paddingSizePhone 0;
}

.paddingTopBottomBigPhone {
  padding: $fontSizePhone 0;
}

.paddingLeftRightPhone {
  padding: 0 $paddingSizePhone;
}

.paddingTopLeftRightPhone {
  padding: $paddingSizePhone $paddingSizePhone 0 $paddingSizePhone;
}

.paddingTopLeftRightBigPhone {
  padding: $fontSizePhone $fontSizePhone 0 $fontSizePhone;
}

.paddingBottomLeftRightPhone {
  padding: 0 $paddingSizePhone $paddingSizePhone $paddingSizePhone;
}

.paddingLeftRightBigPhone {
  padding-left: $fontSizePhone;
  padding-right: $fontSizePhone;
}

.paddingBottomLeftRightBigPhone {
  padding: 0 $fontSizePhone $fontSizePhone $fontSizePhone;
}


.marginPhone {
  margin: $paddingSizePhone;
}

.marginTopPhone {
  margin-top: $paddingSizePhone;
}

.marginTopBigPhone {
  margin-top: $fontSizePhone;
}

.marginBottomPhone {
  margin-bottom: $paddingSizePhone;
}

.marginTop0Phone {
  margin-top: 0
}

.marginLeftPhone {
  margin-left: $paddingSizePhone;
}

.marginLeftBigPhone {
  margin-left: $fontSizePhone;
}

.marginLeftRightPhone {
  margin: 0 $paddingSizePhone;
}

.margin0AutoPhone {
  margin: 0 auto
}

.paddingTopPageTitlePhone {
  padding-top: $marginTopPageTitle;
}

.paddingInsetBottomPhone {
  padding-bottom: $insetBottom;
}

.marginInsetBottomPhone {
  margin-bottom: $insetBottom;
}

.childrenSpacingTopPhone {
  & > *:first-child {
    margin-top: 0;
  }

  & > * {
    @extend .marginTopPhone;
  }
}

.childrenSpacingTopBigPhone {
  & > *:first-child {
    margin-top: 0;
  }

  & > * {
    @extend .marginTopBigPhone;
  }
}

.childrenSpacingLeftPhone {
  & > *:first-child {
    margin-left: 0;
  }

  & > * {
    @extend .marginLeftPhone;
  }
}

.childrenSpacingLeftSmallPhone {
  & > *:first-child {
    margin-left: 0;
  }

  & > * {
    margin-left: $paddingSizePhone/2;
  }
}

.childrenSpacingLeftBigPhone {
  & > *:first-child {
    margin-left: 0;
  }

  & > * {
    @extend .marginLeftBigPhone;
  }
}

.childrenSpacingRightBigPhone {
  & > * {
    margin-right: $fontSizePhone;
  }
}

.childrenSpacingLeftBiggerPhone {
  & > *:first-child {
    margin-left: 0;
  }

  & > * {
    margin-left: $fontSizePhone*2;
  }
}

.childrenSpacingTopBiggerPhone {
  & > *:first-child {
    margin-top: 0;
  }

  & > * {
    margin-top: $fontSizePhone*2;
  }
}


.bigFontSizePhone {
  font-size: $fontSizePhone/$smallMultiple;
}

.biggerFontSizePhone {
  font-size: $fontSizePhone*2;
}

.fontSizePhone {
  font-size: $fontSizePhone;
}

.smallFontSizePhone {
  font-size: $smallFontSizePhone;
}

.smallerFontSizePhone {
  font-size: $smallFontSizePhone*$smallMultiple;
}

.landingPagePaddingTopBottomPhone {
  padding-top: 3rem;
  padding-bottom: 3rem
}

.squareFontSizePhone {
  @include square($fontSizePhone)
}

.nationalFlagSizePhone {
  @include circle($fontSizePhone*1.2);
}

.borderRadiusPhone {
  border-radius: $paddingSizePhone;
}

.power360BigButtonPhone {
  overflow: hidden;
  @extend .cursorPointer, .floatingUpProjection, .borderRadiusPhone, .paddingTopBottomPhone, .textAlignCenter;
}

.power360ButtonPhone {
  @extend .power360BigButtonPhone, .power360BackgroundColor;
}

.unreadPointPhone {
  background: red;
  @include circle($fontSizePhone);
}

.borderBottomSmallGrayLinePhone {
  border-bottom: solid $smallGrayLineSizePhone $lightGray;
}

.borderBottomSmallLinePhone {
  border-bottom: solid .1rem;
}

.smallButtonBorderRadiusPhone {
  border-radius: $fontSizePhone/3
}

.littleDataContainerPhone {
  padding: $paddingSizePhone/5 $paddingSizePhone/2;
  @extend .smallFontSizePhone, .smallButtonBorderRadiusPhone;
}

.borderSmallBlackLinePhone {
  border: solid $smallGrayLineSizePhone black;
}

.borderSmallGrayLinePhone {
  border: solid $smallGrayLineSizePhone $lightGray;
}

.cycleTagPhone {
  border-bottom-right-radius: $paddingSizePhone;
  @extend .positionAbsolute, .paddingSmallPhone, .smallerFontSizePhone, .colorWhite
}

.borderSmallBlackLineDataContainerPhone {
  padding: .1rem .3rem;
  @extend .borderSmallGrayLinePhone, .smallButtonBorderRadiusPhone, .textOverflowEllipsis, .smallerFontSizePhone;
}

.borderLeftSmallGrayLinePhone {
  border-left: solid $smallGrayLineSizePhone $lightGray;
}

button {
  @extend .textAlignCenter;
}

.borderTopSmallGrayLinePhone {
  border-top: solid $smallGrayLineSizePhone $lightGray;
}

.borderTopGrayLinePhone {
  border-top: solid $grayLineSizePhone $lightGray;
}

.iconWidthBigPhone {
  width: $fontSizePhone*1.6;
}

.power360DataTitleBottomSmallLine {
  @extend .paddingBottomBigPhone, .borderBottomSmallLinePhone;
}

.tagBorderRadius {
  overflow: hidden;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.power360BorderPhone {
  border: solid $smallGrayLineSizePhone $power360Color;
}

.activeColor {
  color: $activeColor;
}

.backgroundActiveColor {
  background: $activeColor;
}

.borderActiveColor {
  border: solid $grayLineSize $activeColor;
}

.top0 {
  top: 0
}

.sectionChild {
  overflow: hidden;
  @extend .height100, .flex, .alignItemsCenter, .pagePaddingLeftRight
}

.jsgif {
  @extend .textAlignCenter
}

.linearGreen {
  background: linear-gradient(180deg, #BAFF00 0%, #FFFFFF 100%)
}